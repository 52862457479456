<template>
  <div class="px-6 max-w-screen-2xl mx-auto">
    <FileDnDUploader
      title="Logo üleslaadimine"
      accept="image/*"
      @onFile="onFile"
      @onError="onFileError"
    />
    <div class="my-6" v-if="url">
      <div class="flex items-center justify-center space-x-6">
        <img class="mt-6 max-w-xs flex-shrink-0 object-contain" :src="url" />
        <div class="max-w-xs">
          <button
            @click="upload"
            class="mt-4 whitespace-nowrap cursor-pointer inline-flex justify-center items-center py-2.5 px-5 border border-transparent shadow-small text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-transparent hover:border-indigo-600 hover:text-indigo-600 transition duration-300 ease-in-out"
          >
            Salvesta logo
          </button>
          <p class="mt-4 mx-auto text-base font-light text-gray-500">
            Palun kontrollige üle logo mida proovite üles laadida ja veenduge
            selle korrektsuses.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { currentUser } from "@/firebase/firebase";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import FileDnDUploader, {
  FileDnDUploadError,
} from "@/components/common/FileDnDUploader.vue";
import { ToastType, useAppStore } from "@/store/AppStore";

export default defineComponent({
  components: { FileDnDUploader },
  emits: {
    uploaded: () => true,
  },
  setup(_, { emit }) {
    const appStore = useAppStore();

    const imageData = ref<File | null>(null);
    const url = ref<string | null>(null);
    const municipalityStore = useMunicipalityStore();

    const onFile = (file: File) => {
      imageData.value = file;
      url.value = URL.createObjectURL(imageData.value);
    };

    const upload = async () => {
      const uploaded = await municipalityStore.uploadLogo({
        file: imageData.value,
      });
      if (uploaded) emit("uploaded");
    };

    const onFileError = (e: FileDnDUploadError) => {
      switch (e) {
        case FileDnDUploadError.WRONG_FORMAT:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Vigane faili formaat",
            message: "Palun veenduge et fail oleks pildi formaat",
          });
          break;
        case FileDnDUploadError.MULTIPLE:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Liiga palju faile",
            message: "Pulun laadige ülesse üks fail korraga",
          });
          break;
        default:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Miskit läks valesti",
            message: "Palun proovige uuesti",
          });
          break;
      }
    };

    return {
      currentUser,
      imageData,
      url,
      upload,
      onFile,
      onFileError,
    };
  },
});
</script>
