
import { defineComponent, ref } from "vue";
import UploadLogoUploader from "@/components/uploadlogo/UploadLogoUploader.vue";
import UploadLogoUploaded from "@/components/uploadlogo/UploadLogoUploaded.vue";
import MunicipalityPathGuard from "@/components/common/MunicipalityPathGuard.vue";

export default defineComponent({
  components: { MunicipalityPathGuard, UploadLogoUploaded, UploadLogoUploader },
  setup() {
    const uploaded = ref(false);

    return {
      uploaded,
    };
  },
});
