
import { defineComponent, ref } from "vue";
import { HTMLInputEvent } from "@/common/types";

export enum FileDnDUploadError {
  WRONG_FORMAT = "WRONG_FORMAT",
  MULTIPLE = "MULTIPLE",
}

export default defineComponent({
  name: "FileDnDUploader",
  emits: {
    onFile: (file: unknown) => !!file,
    onFiles: (files: unknown) => Array.isArray(files),
    onError: (e: unknown) =>
      typeof e === "string" &&
      Object.values(FileDnDUploadError).includes(e as FileDnDUploadError),
  },
  props: {
    accept: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dragging = ref(false);
    const onDragOver = (event: DragEvent) => {
      dragging.value = true;
      event.preventDefault();
    };
    const onDragLeave = () => (dragging.value = false);

    const onDrop = (event: DragEvent) => {
      event.preventDefault();
      dragging.value = false;
      handleFiles(event.dataTransfer?.files ?? null);
    };

    const onInputChange = (event: HTMLInputEvent) =>
      handleFiles(event.target.files);

    const isFileMatching = (type: string) => {
      const acceptedParts = props.accept.split("/");
      return acceptedParts[1] === "*"
        ? type.split("/")[0] === acceptedParts[0]
        : type === props.accept;
    };

    const handleFiles = (files: FileList | null) => {
      if (!files?.length) return;

      const matchingFiles = Array.from(files).filter((file) =>
        isFileMatching(file.type)
      );
      if (!matchingFiles.length) {
        emit("onError", FileDnDUploadError.WRONG_FORMAT);
        return;
      }

      if (!props.multiple && matchingFiles.length > 1) {
        emit("onError", FileDnDUploadError.MULTIPLE);
        return;
      }

      if (!props.multiple) emit("onFile", matchingFiles[0]);

      emit("onFiles", matchingFiles);
    };
    return {
      dragging,
      onDragOver,
      onDragLeave,
      onDrop,
      onInputChange,
    };
  },
});
