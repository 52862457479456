<template>
  <div class="mt-4">
    <MunicipalityPathGuard>
      <div class="w-full">
        <UploadLogoUploader v-if="!uploaded" @uploaded="uploaded = true" />
        <UploadLogoUploaded v-else @uploadAgain="uploaded = false" />
      </div>
    </MunicipalityPathGuard>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import UploadLogoUploader from "@/components/uploadlogo/UploadLogoUploader.vue";
import UploadLogoUploaded from "@/components/uploadlogo/UploadLogoUploaded.vue";
import MunicipalityPathGuard from "@/components/common/MunicipalityPathGuard.vue";

export default defineComponent({
  components: { MunicipalityPathGuard, UploadLogoUploaded, UploadLogoUploader },
  setup() {
    const uploaded = ref(false);

    return {
      uploaded,
    };
  },
});
</script>
