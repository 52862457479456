<template>
  <div
    class="py-6 flex items-center rounded-md border-2 border-dashed transform duration-300 ease-in-out"
    :class="dragging ? 'border-indigo-200 bg-indigo-50' : 'border-gray-200'"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    <div class="max-w-xl mx-auto text-center">
      <div class="flex flex-col items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-20 w-20 my-2 transform duration-300 ease-in-out"
          :class="dragging ? 'text-indigo-600' : 'text-gray-600'"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0.5"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg>
        <h2
          class="text-3xl leading-8 font-semibold tracking-tight text-gray-900 mb-3"
        >
          {{ title }}
        </h2>
      </div>

      <div class="mt-2 space-x-2 inline-flex items-center">
        <span class="text-lg font-light text-gray-800">
          Lohista fail siia aknasse või
        </span>
        <label
          class="cursor-pointer font-semibold text-base underline text-indigo-600 hover:text-primary-teal transition duration-300 ease-in-out"
          for="fileUploadDnD"
        >
          <span>Vali kaustast</span>
        </label>
        <input
          type="file"
          name="fileUploadDnD"
          id="fileUploadDnD"
          :accept="accept"
          :disabled="false"
          :multiple="multiple"
          @change="onInputChange"
          class="hidden"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { HTMLInputEvent } from "@/common/types";

export enum FileDnDUploadError {
  WRONG_FORMAT = "WRONG_FORMAT",
  MULTIPLE = "MULTIPLE",
}

export default defineComponent({
  name: "FileDnDUploader",
  emits: {
    onFile: (file: unknown) => !!file,
    onFiles: (files: unknown) => Array.isArray(files),
    onError: (e: unknown) =>
      typeof e === "string" &&
      Object.values(FileDnDUploadError).includes(e as FileDnDUploadError),
  },
  props: {
    accept: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dragging = ref(false);
    const onDragOver = (event: DragEvent) => {
      dragging.value = true;
      event.preventDefault();
    };
    const onDragLeave = () => (dragging.value = false);

    const onDrop = (event: DragEvent) => {
      event.preventDefault();
      dragging.value = false;
      handleFiles(event.dataTransfer?.files ?? null);
    };

    const onInputChange = (event: HTMLInputEvent) =>
      handleFiles(event.target.files);

    const isFileMatching = (type: string) => {
      const acceptedParts = props.accept.split("/");
      return acceptedParts[1] === "*"
        ? type.split("/")[0] === acceptedParts[0]
        : type === props.accept;
    };

    const handleFiles = (files: FileList | null) => {
      if (!files?.length) return;

      const matchingFiles = Array.from(files).filter((file) =>
        isFileMatching(file.type)
      );
      if (!matchingFiles.length) {
        emit("onError", FileDnDUploadError.WRONG_FORMAT);
        return;
      }

      if (!props.multiple && matchingFiles.length > 1) {
        emit("onError", FileDnDUploadError.MULTIPLE);
        return;
      }

      if (!props.multiple) emit("onFile", matchingFiles[0]);

      emit("onFiles", matchingFiles);
    };
    return {
      dragging,
      onDragOver,
      onDragLeave,
      onDrop,
      onInputChange,
    };
  },
});
</script>

<style scoped></style>
