<template>
  <slot></slot>
</template>

<script lang="ts">
import { computed, defineComponent, watchEffect } from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { useUserStore } from "@/store/UserStore";
import { useRouter } from "vue-router";
import { RouteName } from "@/router/types";

export default defineComponent({
  name: "MunicipalityPathGuard",
  setup() {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const userStore = useUserStore();
    const userState = userStore.getState();
    const userInfo = computed(() => userState.userInfo);
    const userMunicipalitySlug = computed(
      () => userInfo.value?.municipalitySlug
    );
    const pathMunicipalitySlug = computed(
      () => municipalityState.municipality?.uid
    );
    const router = useRouter();
    watchEffect(() => {
      if (!userMunicipalitySlug.value) {
        pathMunicipalitySlug.value
          ? router.push({
              name: RouteName.MUNICIPALITY_HOME,
              params: { municipalitySlug: pathMunicipalitySlug.value },
            })
          : router.push({ name: RouteName.HOME });
        return;
      }
      if (userMunicipalitySlug.value !== pathMunicipalitySlug.value) {
        router.push({
          name: RouteName.MUNICIPALITY_HOME,
          params: { municipalitySlug: userMunicipalitySlug.value },
        });
      }
    });
    return {};
  },
});
</script>

<style scoped></style>
