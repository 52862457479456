
import { defineComponent, ref } from "vue";
import { currentUser } from "@/firebase/firebase";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import FileDnDUploader, {
  FileDnDUploadError,
} from "@/components/common/FileDnDUploader.vue";
import { ToastType, useAppStore } from "@/store/AppStore";

export default defineComponent({
  components: { FileDnDUploader },
  emits: {
    uploaded: () => true,
  },
  setup(_, { emit }) {
    const appStore = useAppStore();

    const imageData = ref<File | null>(null);
    const url = ref<string | null>(null);
    const municipalityStore = useMunicipalityStore();

    const onFile = (file: File) => {
      imageData.value = file;
      url.value = URL.createObjectURL(imageData.value);
    };

    const upload = async () => {
      const uploaded = await municipalityStore.uploadLogo({
        file: imageData.value,
      });
      if (uploaded) emit("uploaded");
    };

    const onFileError = (e: FileDnDUploadError) => {
      switch (e) {
        case FileDnDUploadError.WRONG_FORMAT:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Vigane faili formaat",
            message: "Palun veenduge et fail oleks pildi formaat",
          });
          break;
        case FileDnDUploadError.MULTIPLE:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Liiga palju faile",
            message: "Pulun laadige ülesse üks fail korraga",
          });
          break;
        default:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Miskit läks valesti",
            message: "Palun proovige uuesti",
          });
          break;
      }
    };

    return {
      currentUser,
      imageData,
      url,
      upload,
      onFile,
      onFileError,
    };
  },
});
